import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { SocialIcon } from "react-social-icons"

const social = [
  "https://www.linkedin.com/in/robertburrelldonkin",
  "https://github.com/RobertBurrellDonkin",
  "https://twitter.com/itstechupnorth"]

const Header = ({ siteTitle }) => (
  <header
    style={{
      textAlign: "center",
      background: `cornflowerblue`,
      marginBottom: `0.5rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem 0 1.45rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
    <div style={{ color: "darkgrey", fontWeight: "bold" }}>
      {social.map(a =>
        <SocialIcon
          key={a}
          url={a}
          bgColor='white'
          fgColor='cornflowerblue'
          target="_blank"
          rel="noopener noreferrer"
          style={{ height: 25, width: 25, margin: "0.5rem" }}/>)
      }
    </div>

  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
